import './assets/scss/quasar.scss';
import '@quasar/extras/roboto-font/roboto-font.css';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';
// import 'quasar/dist/quasar.css';
import iconSet from 'quasar/icon-set/fontawesome-v5';

import {
  QBtn,
  QBtnDropdown,
  QCard,
  QCardActions,
  QCardSection,
  QDrawer,
  QDialog,
  QForm,
  QIcon,
  QInput,
  QItem,
  QItemLabel,
  QItemSection,
  QLayout,
  QPageContainer,
  QScrollArea,
  QSeparator,
  QToolbar,
  QTooltip,
  QHeader,
  QToolbarTitle,
  QList,
  QAvatar,
  QToggle,
  QExpansionItem,
  QTree,
  QBadge,
  QTabs,
  QSelect,
  QCheckbox,
  QUploader,
  QPage,
  Notify,
  Dialog,
  QTable,
  LoadingBar,
  Loading,
  QColor,
  QSpace,
  QMenu,
  QuasarUIConfiguration,
  ClosePopup,
  QuasarDirectives
} from 'quasar';

interface QuasarLoadingBarConfig {
  size?: string,
  position?: "bottom" | "top" | "right" | "left" | undefined
}

interface QuasarUIConfigurationEx extends QuasarUIConfiguration {
  loadingBar: QuasarLoadingBarConfig,
  dark?: boolean
}

const config: QuasarUIConfigurationEx = {
  loadingBar: {
    size: '5px',
    position: 'bottom',
  }
};

export const quasarConfig = {
  config: config,
  components: {
    QBtn,
    QBtnDropdown,
    QCard,
    QCardActions,
    QCardSection,
    QDrawer,
    QDialog,
    QForm,
    QIcon,
    QInput,
    QItem,
    QPage,
    QItemLabel,
    QItemSection,
    QLayout,
    QPageContainer,
    QScrollArea,
    QSeparator,
    QToolbar,
    QTooltip,
    QHeader,
    QToolbarTitle,
    QList,
    QAvatar,
    QToggle,
    QExpansionItem,
    QTree,
    QBadge,
    QTabs,
    QSelect,
    QCheckbox,
    QTable,
    QUploader,
    QColor,
    QSpace,
    QMenu
  },
  plugins: {
    Notify,
    Dialog,
    LoadingBar,
    Loading
  },
  directives: {
    vClosePopup: ClosePopup
  } as QuasarDirectives,
  iconSet: iconSet
  /*
  extras: [
    'fontawesome-v5',    
    'roboto-font',
    'material-icons'
  ]
  */
};
