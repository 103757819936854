import { AxiosResponse } from "axios";

import { api } from "@/api/ApiBase";
import { SearchResponse } from "../common/SearchResponse";
import { ContentTypeSearchParametersData } from "./ContentTypeSearchParameters";
import { ContentTypeSearchItemData } from "./ContentTypeSearchItemData";

/**
 * Contents api.
 */
export class ContentsApi {

  /**
   * Searches content types.
   */
  public static searchContentTypes(request: ContentTypeSearchParametersData): Promise<AxiosResponse<SearchResponse<ContentTypeSearchItemData>>> {
    return api.client.get('/api/contenttypes/search', {
      params: request
    });
  }
}
