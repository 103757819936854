import { createRouter, createWebHashHistory } from 'vue-router';
import HomeView from '@/modules/home/views/HomeView.vue';
import dashboardRoutes from '@/modules/dashboard/routes';
import thingRoutes from '@/modules/things/routes';

const routes = [
  ...dashboardRoutes,
  ...thingRoutes,
  {
    path: '/:pathMatch(.*)*',
    name: 'Home',
    component: HomeView,
    meta: {
      requiresAuth: false,
      title: 'Home',
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
})

export default router;
