import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!

  return (_openBlock(), _createBlock(_component_q_list, {
    padding: "",
    class: "menu-list"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createBlock(_resolveDynamicComponent(item.children ? 'QExpansionItem' : 'QItem'), {
          key: index,
          clickable: "",
          onClick: ($event: any) => (item.route && _ctx.$router.push(item.route).catch(err => {})),
          active: _ctx.isItemActive(item),
          "active-class": "bg-accent text-white text-bold",
          "default-opened": _ctx.isItemActive(item),
          icon: item.icon,
          label: item.title
        }, {
          default: _withCtx(() => [
            (!item.children)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_q_item_section, { avatar: "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_icon, {
                        name: item.icon
                      }, null, 8, ["name"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.title), 1)
                    ]),
                    _: 2
                  }, 1024)
                ], 64))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (subItem, subIndex) => {
              return (_openBlock(), _createBlock(_resolveDynamicComponent(subItem.children ? 'QExpansionItem' : 'QItem'), {
                key: subIndex,
                clickable: "",
                onClick: ($event: any) => (subItem.route && _ctx.$router.push(subItem.route).catch(err => {})),
                active: _ctx.isItemActive(subItem),
                "active-class": "bg-accent text-white text-bold",
                "default-opened": _ctx.isItemActive(subItem),
                icon: subItem.icon,
                label: subItem.title
              }, {
                default: _withCtx(() => [
                  (!subItem.children)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createVNode(_component_q_item_section, { avatar: "" }),
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(subItem.title), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ], 64))
                    : _createCommentVNode("", true),
                  (subItem.children)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(subItem.children, (subChild, childIndex) => {
                        return (_openBlock(), _createBlock(_component_q_item, {
                          key: childIndex,
                          active: _ctx.isItemActive(subChild),
                          "active-class": "bg-accent text-white text-bold",
                          clickable: "",
                          onClick: ($event: any) => (
              subChild.route && _ctx.$router.push(subChild.route).catch(err => {})
            )
                        }, {
                          default: _withCtx(() => [
                            (!subChild.children)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createVNode(_component_q_item_section, { avatar: "" }),
                                  _createVNode(_component_q_item_section, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(subChild.title), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ], 64))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["active", "onClick"]))
                      }), 128))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["onClick", "active", "default-opened", "icon", "label"]))
            }), 128))
          ]),
          _: 2
        }, 1032, ["onClick", "active", "default-opened", "icon", "label"]))
      }), 128))
    ]),
    _: 1
  }))
}