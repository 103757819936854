import { LoginResponse } from "./LoginResponse";
import { AxiosResponse } from "axios";

import { api } from "@/api/ApiBase";
import { LoginRequest } from "./LoginRequest";

/**
 * Users api.
 */
export class UsersApi {
  /**
   * Login
   */
  public static login(request: LoginRequest): Promise<AxiosResponse<LoginResponse>> {
    return api.client.post('/api/user/login', request);
  }
}
