import { AxiosResponse } from "axios";

import { api } from "@/api/ApiBase";
import { ThingSearchItemData } from "./ThingSearchItemData";
import { SearchResponse } from "../common/SearchResponse";
import { ThingSearchParametersData } from "./ThingSearchParametersData";
import { GetThingResponse } from "./GetThingResponse";
import { ThingDetailData } from "./ThingDetailData";
import { FileSearchItemData } from "../files/FileSearchItemData";

/**
 * Things api.
 */
export class ThingsApi {
  /**
   * Search
   */
  public static search(request: ThingSearchParametersData): Promise<AxiosResponse<SearchResponse<ThingSearchItemData>>> {
    return api.client.get('/api/things/search', {
      params: request
    });
  }

  /**
   * Creates a thing from a file.
   */
  public static createFromFile(file: File): Promise<AxiosResponse<GetThingResponse>> {
    const formData = new FormData();
    formData.append("file", file);
    return api.client.post('/api/things/fromfile', formData, { 
      headers: {
        "content-type": "multipart/form-data"
      }
    });
  }

  /**
   * Gets details for a thing.
   */
  public static get(id: string): Promise<AxiosResponse<GetThingResponse>> {
    return api.client.get(`/api/things/${id}`);
  }

  /**
   * Saves a thing.
   */
  public static save(thing: ThingDetailData): Promise<AxiosResponse<GetThingResponse>> {
    return api.client.post(`/api/things/${thing.id}`, thing);
  }

  /**
   * Changes the content type of a thing.
   */
  public static changeContentType(id: string, contentTypeId: string): Promise<AxiosResponse<GetThingResponse>> {
    return api.client.post(`/api/things/${id}/changecontenttype`, { contentTypeId });
  }

  /**
   * Gets links for a thing.
   */
  public static getLinks(id: string, propertyName: string): Promise<AxiosResponse<SearchResponse<ThingSearchItemData>>> {
    return api.client.get(`/api/things/${id}/links/${propertyName}`);
  }

  /**
   * Links two thing.
   */
  public static createLink(sourceId: string, targetId: string, propertyName: string): Promise<AxiosResponse> {
    return api.client.post(`/api/things/${sourceId}/links/${propertyName}/${targetId}`);
  }

  /**
   * Deletes a link.
   */
  public static deleteLink(sourceId: string, targetId: string, propertyName: string): Promise<AxiosResponse> {
    return api.client.delete(`/api/things/${sourceId}/links/${propertyName}/${targetId}`);
  }

  /**
   * Gets files for a thing.
   */
  public static getFiles(id: string, propertyName: string|null = null): Promise<AxiosResponse<SearchResponse<FileSearchItemData>>> {
    return api.client.get(`/api/things/${id}/files/${propertyName ?? ''}`);
  }

  /**
   * Uploads a file to a thing.
   */
  public static uploadFile(id: string, file: File): Promise<AxiosResponse> {
    const formData = new FormData();
    formData.append("file", file);
    return api.client.post(`/api/things/${id}/files/`, formData, { 
      headers: {
        "content-type": "multipart/form-data"
      }
    });
  }
}
