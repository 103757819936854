
import useUserStore from "@/modules/auth/store";
import { defineComponent } from "vue";

export default defineComponent({
  setup() {

    const { startLogin } = useUserStore();

    return {
      login: () => startLogin(''),
      register: () => { console.log('register'); }
    };
  }
})
