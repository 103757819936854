/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/* fetch from config/propnames endpoint if updates needed */
const propNames = new Map<string, string>([
  ['ancestors', 'ancs'],
  ['content', 'c'],
  ['contentType', 'ct'],
  ['contentTypeId', 'cti'],
  ['data', 'd'],
  ['creation', 'dc'],
  ['modification', 'dm'],
  ['errorCode', 'ec'],
  ['errorMessage', 'em'],
  ['fieldId', 'fi'],
  ['previewUrl', 'fp'],
  ['hint', 'hnt'],
  ['id', 'i'],
  ['items', 'it'],
  ['email', 'm'],
  ['mimeType', 'mt'],
  ['name', 'n'],
  ['propertyName', 'pn'],
  ['password', 'pw'],
  ['relationType', 'rel'],
  ['startIndex', 'si'],
  ['accessToken', 't'],
  ['totalCount', 'tc'],
  ['accessTokenExpiration', 'te'],
  ['title', 'ti'],
  ['targetId', 'tid'],
  ['text', 'txt'],
  ['type', 'ty'],
  ['user', 'u'],
  ['username', 'un'],
  ['url', 'url'],
  ['version', 'v'],
  ['values', 'vals'],
  ['view', 'vi']
]);

// list of property names for which child property names should be preserved
const preserveChildPropNames = ['values']

const fullToShortPropNameMap: any = {};
const shortToFullPropNameMap: any = {};

propNames.forEach((short: string, full: string) => {
  fullToShortPropNameMap[full] = short;
  shortToFullPropNameMap[short] = full;
})

export function transformPropertyNames(data: any, keepPropNames: boolean): any {
  if (Array.isArray(data)) return data;
  const newData: any = {};
  Object.keys(data).forEach(key => {
    const newPropName = keepPropNames
      ? key
      : fullToShortPropNameMap[key] || key;
    const newPropValue = data[key];
    newData[newPropName] = typeof(newPropValue) === 'object'
      ? transformPropertyNames(newPropValue, preserveChildPropNames.includes(key))
      : newPropValue;
  });
  return newData;
}

export function untransformPropertyNames(data: any, keepPropNames: boolean): any {
  if (Array.isArray(data)) 
  {
      for (let i = 0; i < data.length; i++)
      {
        data[i] = typeof(data[i]) === 'object' ? untransformPropertyNames(data[i], false) : data[i];
      }
      return data;
  }
  const newData: any = {};
  Object.keys(data).forEach(key => {
    const newPropName = keepPropNames
      ? key
      : shortToFullPropNameMap[key] || key;
    const newPropValue = data[key];
    newData[newPropName] = typeof(newPropValue) === 'object'
      ? untransformPropertyNames(newPropValue, preserveChildPropNames.includes(newPropName))
      : newPropValue;
  });
  return newData;
}