
import { defineComponent } from "vue";
import { useRoute } from "vue-router";

interface MenuItem {
  icon?: string;
  route?: string;
  title?: string;
  children?: MenuItem[];
}

export default defineComponent({
  name: "MainMenu",
  setup() {

    const items: MenuItem[] = [
      {
        icon: 'speed',
        route: '/dashboard',
        title: 'Dashboard',
      },
      {
        icon: 'logout',
        title: 'Logout',
        route: '/login',
      },
    ];
    const route = useRoute();

    /**
     * Tests if item is active.
     */
    function isItemActive(item: MenuItem): boolean {
      if (item.route === '/' && route.path !== '/') return false;
      if (
        item.route !== undefined &&
        route.path.indexOf(`${item.route}`) >= 0
      )
        return true;
      if (item.children) {
        for (let i = 0; i < item.children.length; i++) {
          if (isItemActive(item.children[i])) return true;
        }
      }
      return false;
    }

    return {
      items,
      isItemActive
    }
  }
})

