import AddThingView from '../views/AddThingView.vue';
import ThingDetailView from '../views/ThingDetailView.vue';

const routes = [
  {
    path: '/things/add',
    name: 'addThing',
    component: AddThingView,
    meta: {
      requiresAuth: true,
      title: 'Add thing',
    },
  },
  {
    path: '/things/:id',
    name: 'thingDetail',
    component: ThingDetailView,
    meta: {
      requiresAuth: true,
      title: 'Detail',
    },
  },
];

export default routes;
