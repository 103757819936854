import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6f1228cf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "things-view things-view-nopadding" }
const _hoisted_2 = { class: "text-center things-add-button fixed-bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ThingGallery = _resolveComponent("ThingGallery")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ThingGallery, {
      ref: "gallery",
      onItemClick: _ctx.onItemClicked
    }, null, 8, ["onItemClick"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_q_btn, {
        icon: "add",
        round: "",
        color: "primary",
        size: "lg"
      }, {
        default: _withCtx(() => [
          _createElementVNode("input", {
            type: "file",
            accept: "image/*",
            capture: "",
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFileSelected && _ctx.onFileSelected(...args)))
          }, null, 32)
        ]),
        _: 1
      })
    ])
  ]))
}