import { defineAsyncComponent } from 'vue';
import { ViewItemTypeDescriptor } from './ViewItemTypeDescriptor';

/**
 * List of known condition components
 */
export const viewItemTypes: ViewItemTypeDescriptor[] = [
  { type: 'section', component: defineAsyncComponent(() => import('@/modules/common/components/Views/ViewSection.vue')) },
  { type: 'control', component: defineAsyncComponent(() => import('@/modules/common/components/Views/UnknownViewItem.vue')) },
  { type: 'objectLinksViewControl', component: defineAsyncComponent(() => import('@/modules/common/components/Views/controls/ObjectLinksViewControl.vue')) },
  { type: 'translatedTextViewControl', component: defineAsyncComponent(() => import('@/modules/common/components/Views/controls/TranslatedTextViewControl.vue')) },
];
