import { AxiosResponse } from 'axios';
import { api } from '@/api/ApiBase';
import { GetProfileResponse } from './GetProfileResponse';

/**
 * Profile api.
 */
export class ProfileApi {
  /**
   * Gets the profile for the current user.
   */
  public static getProfile(): Promise<AxiosResponse<GetProfileResponse>> {
    return api.client.get('/api/profile');
  }
}
