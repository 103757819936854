

import MainMenu from "@/modules/common/components/MainMenu/index.vue";
import LoginDialog from "./modules/auth/components/LoginDialog.vue";
import { defineComponent, ref, watch } from "vue";
import useUserStore from "./modules/auth/store";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {

    const { isLoggingIn, cancelLogin, currentUser, isLoggedIn, logout } = useUserStore();
    const pinDrawer = ref(false);
    const miniState = ref(true);

    const router = useRouter();

    router.beforeEach((route, from, next) => {
      document.title = 'Things - ' + (route.meta.title as string || 'Home');
      if (route.meta.requiresAuth && !isLoggedIn.value) {
        next({ path: '/' })
      } else if (route.path === '/' && isLoggedIn.value) {
        next({ path: '/dashboard' })
      } else {
        next()
      }
    });

    watch(() => isLoggedIn.value, (isLoggedIn) => {
      if (!isLoggedIn) {
        router.push('/');
      } else {
        router.push('/dashboard');
      }
    });

    return {
      pinDrawer,
      miniState,
      isLoggingIn,
      isLoggedIn,
      currentUser,
      cancelLogin,
      logout
    }
  },
  components: {
    LoginDialog,
    MainMenu
  }
})
