import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { Quasar } from "quasar";
import { quasarConfig } from "./quasar";
import "./assets/scss/main.scss";
import useUserStore from "./modules/auth/store";

const app = createApp(App);

const { restoreToken, loadCurrentUser } = useUserStore();
restoreToken();
await loadCurrentUser();

app.use(router);
app.use(Quasar, quasarConfig);
app.mount('#app');
