
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "ThumbnailImage",
  props: {
    value: String,
    url: String
  },
  setup (props) {

    const imageUrl = computed(() => props.url);
    //       return FilesApi.getThumbnailUrl(this.value);
  
    return {
      imageUrl
    };
  }
});

