import { Notify } from 'quasar';

/**
 * Show toast message
 */
export function showToast(message: string, color: string): void {
  try {
    Notify.create({
      color,
      message,
      position: 'top-right',
      timeout: 1500,
      progress: true,
    });
  } catch (e) {
    console.error(e);
  }
}

/**
 * Show success notification
 */
export function showSuccess(message?: string): void {
  showToast(message || 'Success', 'positive');
}

/**
 * Error notification
 */
export function showError(message?: string): void {
  showToast(message || 'Error', 'negative');
}
