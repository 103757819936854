
import { computed, defineComponent, ref, watch } from "vue";
import useUserStore from "../store";

export default defineComponent({
  props: {
    show: Boolean
  },
  setup (props) {

    const showDialog = ref(false);
    const usernameError = ref(false);
    const username = ref('');
    const password = ref('');
    const passwordError = ref(false);
    const userStore = useUserStore();

    async function loginWithCredentials(): Promise<void> {
      usernameError.value = !username.value;
      passwordError.value = !password.value;
      if (usernameError.value || passwordError.value) {
        return;
      }

      await userStore.loginWithCredentials(username.value, password.value);
    }

    async function loginWithApple(): Promise<void> {
      const returnUrl = window.location.href.toString().split('?')[0];
      window.AppleID.auth.init({
        clientId: 'com.kickbase.play',
        scope: '',
        redirectURI: returnUrl,
        responseType: 'code',
        state: Math.random().toString(),
        nonce: Math.random().toString(),
        usePopup: true
      });
      try {
        const response = await window.AppleID.auth.signIn();
        const idToken = response.authorization.id_token;
        await userStore.loginWithApple(idToken);
      } catch(e) {
        console.log('ERR', e);
      }
    }

    watch(() => props.show, (newValue) => {
      showDialog.value = newValue;
    })

    return {
      showDialog,
      username,
      usernameError,
      password,
      passwordError,
      loginMessage: computed(() => userStore.loginMessage.value),
      loginWithCredentials,
      loginWithApple
    }
  },
})

