
import { defineComponent, ref } from "vue";
import ThingGallery from "@/modules/things/components/ThingGallery/index.vue";
import { ThingSearchItemData } from "@/api/things/ThingSearchItemData";
import { useRouter } from "vue-router";
import { ThingsApi } from "@/api/things";

export default defineComponent({
  components: {
    ThingGallery
  },
  setup() {

    const router = useRouter();
    const gallery = ref<typeof ThingGallery|null>();

    function onFileSelected(evt: Event) {
      const input = evt.target as HTMLInputElement;
      ([...input.files || []]).forEach(async file => {
        await ThingsApi.createFromFile(file);
        gallery.value?.loadItems();
      });
      input.value = '';
    }

    function onItemClicked(item: ThingSearchItemData) {
      router.push({ name: 'thingDetail', params: { id: item.id } });
    }

    return {
      onFileSelected,
      onItemClicked,
      gallery
    };
  }
});

